import React from 'react'
import { Helmet } from 'react-helmet'

export const StandardImage = props => {
    const { url, mobileUrl, alt, title, loadingMethod, onClick, isFullWidth } = props
    return (
        <>
            {mobileUrl ? (
                <>
                    <picture style={{ width: isFullWidth ? '100%' : 'auto' }}>
                        <source srcSet={url} media="(min-width: 768px)" />
                        <img
                            src={mobileUrl}
                            alt={alt}
                            title={title}
                            {...(typeof onClick === 'function' && {
                                onClick,
                                style: { cursor: 'zoom-in' },
                            })}
                            {...(loadingMethod === 'lazyload' && { loading: 'lazy' })}
                        />
                    </picture>

                    {loadingMethod === 'preload' && (
                        <Helmet>
                            <link
                                rel="preload"
                                href={mobileUrl}
                                as="image"
                                media="(max-width: 768px)"
                            />
                            <link
                                rel="preload"
                                href={url}
                                as="image"
                                media="(min-width: 768.1px)"
                            />
                        </Helmet>
                    )}
                </>
            ) : (
                <>
                    <img
                        src={url}
                        alt={alt}
                        title={title}
                        {...(typeof onClick === 'function' && {
                            onClick,
                            style: { cursor: 'zoom-in' },
                        })}
                        {...(loadingMethod === 'lazyload' && { loading: 'lazy' })}
                    />

                    {loadingMethod === 'preload' && (
                        <Helmet>
                            <link rel="preload" href={url} as="image" />
                        </Helmet>
                    )}
                </>
            )}
        </>
    )
}
