import React from 'react'
import { ModalWrapper } from './components'

export const Modal = ({ isOpen, onClose, title, children, ...restProps }) => {
    return (
        <ModalWrapper
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}
            {...restProps}
            data-testid={`modal`}
            className="content__image--modal"
        >
            {children}
        </ModalWrapper>
    )
}

Modal.defaultProps = {
    isOpen: false,
}
