import React from "react";
import { Field } from "formik";

import { Checkbox as TWCheckbox } from "../components/Checkbox";

export const Checkbox = (props) => (
  <div className="mb-[15px]">
    <Field name={props.name}>
      {({ field, form }) => (
        <TWCheckbox
          aria-label={props.label}
          checked={field.value}
          onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
          isDisabled={form.isSubmitting || props.disabled}
          {...props}
        />
      )}
    </Field>

    {props?.help?.length > 0 && (
      <p className="text-brand-dark-grey text-[12px] mb-[15px] md:mb-[30px]">
        {props.help}
      </p>
    )}
  </div>
);

export const FormikCheckbox = (props) => {
  const { label, errorMessage, required, hasError, ...restProps } = props;

  return (
    <div>
      {label?.length > 0 && (
        <label
          htmlFor={restProps?.name || restProps?.id}
          className={`block mb-2 font-medium text-base`}
        >
          {label}
          <>
            {required ? (
              <span className="text-brand-danger"> *</span>
            ) : (
              <span className="italic font-normal text-brand-black/80">
                {" "}
                &#8212; optional
              </span>
            )}
          </>
        </label>
      )}
      <Field name={props.name}>
        {({ field, form }) => (
          <Checkbox
            aria-label={props.label}
            checked={field.value}
            onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
            isDisabled={form.isSubmitting || props.disabled}
            {...props}
          />
        )}
      </Field>
    </div>
  );
};
