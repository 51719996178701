const sharedClasses =
    "flex items-center justify-center text-[16px] leading-[1] rounded-[23px] px-[30px] pt-[13px] pb-[12px] font-normal transition-color duration-200 disabled:cursor-not-allowed disabled:opacity-25";

export const primary = {
    solidClasses: `${sharedClasses} bg-brand-black text-brand-white hover:bg-brand-black-darken hover:text-brand-white active:bg-brand-black-darken active:shadow-[0_0_0_4px_rgba(18,23,23,0.4)]`,
    outlineClasses: `${sharedClasses} bg-none text-brand-black border-2 border-brand-black hover:bg-brand-black hover:text-brand-white active:bg-brand-black-darken active:shadow-[0_0_0_4px_rgba(18,23,23,0.4)]`,
    linkClasses: `inline-block !my-[5px] text-[18px] leading-[20px] lg:text-[24px] lg:leading-[28px] text-brand-primary`,
};

export const secondary = {
    solidClasses: `${sharedClasses} bg-brand-dark-grey text-brand-white hover:bg-brand-dark-grey-darken hover:text-brand-white active:bg-brand-dark-grey-darken active:shadow-[0_0_0_4px_rgba(103,122,122,0.4)]`,
    outlineClasses: `${sharedClasses} bg-none text-brand-dark-grey border-2 border-brand-dark-grey hover:bg-brand-dark-grey hover:text-brand-white active:bg-brand-dark-grey-darken active:shadow-[0_0_0_4px_rgba(103,122,122,0.4)]`,
    linkClasses: `inline-block !my-[5px] text-[18px] leading-[20px] lg:text-[24px] lg:leading-[28px] text-brand-dark-grey`,
};

export const tertiary = {
    solidClasses: `${sharedClasses} bg-brand-primary text-brand-white hover:bg-brand-primary-darken hover:text-brand-white active:bg-brand-primary-darken active:shadow-[0_0_0_4px_rgba(0,167,181,0.4)]`,
    outlineClasses: `${sharedClasses} bg-none text-brand-primary border-2 border-brand-primary hover:bg-brand-primary hover:text-brand-white active:bg-brand-primary-darken active:shadow-[0_0_0_4px_rgba(0,167,181,0.4)]`,
    linkClasses: `inline-block !my-[5px]  text-[18px] leading-[20px] lg:text-[24px] lg:leading-[28px] text-brand-white`,
};
