import React, { useEffect, useState } from "react";
import isObjectLike from "lodash/isObjectLike";
import { Image } from "components/Image";
import sortBy from "lodash/sortBy";

/** If you want to override the content below the title/separator, please provide React children */
export const ProductCard = (props) => {
    const {
        children,
        product,
        link,
        productNameProps,
        separatorProps,
        incTax,
        onAddToBasket,
        onAddToWishlist,
        imagePresets,
        imageProps,
        onClick,
        ...restProps
    } = props;

    const [imageLocation, setImageLocation] = useState(
        "/images/placeholder.jpg"
    );

    const productImages = product.resources?.filter((r) => r.type === "image");
    const siloImages = product.resources?.filter((r) => r.type === "silo");
    const firstImage = productImages
        ? sortBy(productImages, ["displayOrder"])[0]
        : null;
    const firstSiloImage = siloImages
        ? sortBy(siloImages, ["displayOrder"])[0]
        : null;

    useEffect(() => {
        if (firstImage?.location) {
            setImageLocation(firstImage.location);
        }
    }, [firstImage]);

    const productRange =
        isObjectLike(product.attributes) && product.attributes.range;
    const productName =
        isObjectLike(product.attributes) && product.attributes.product_name;
    const sizesAvailable =
        isObjectLike(product.attributes) &&
        parseInt(product.attributes.sizes_available as string);

    return (
        <div
            {...restProps}
            className={`flex flex-col ${restProps?.className || ""}`}
            data-testid={`product-card`}
        >
            <a href={`/products/${product.slug}`} className="!no-underline">
                <div className="aspect-square">
                    <Image
                        className="h-full w-full rounded-[15px] overflow-hidden"
                        {...(isObjectLike(product.attributes) && {
                            alt: product.attributes.product_name,
                        })}
                        src={imageLocation}
                        data-testid={`product-card__media`}
                        presets={imagePresets}
                        imageClassName="object-cover"
                        {...imageProps}
                        onMouseEnter={() => {
                            if (firstSiloImage?.location) {
                                setImageLocation(firstSiloImage.location);
                            }
                        }}
                        onMouseLeave={() => {
                            if (firstSiloImage && firstImage?.location) {
                                setImageLocation(firstImage.location);
                            }
                        }}
                    />
                </div>

                {productRange && (
                    <p
                        {...productNameProps}
                        className={`product-card__name text-brand-primary uppercase text-[24px] leading-[28px] mt-[15px] mb-0 ${productNameProps?.className}`}
                        data-testid={`product-card__name`}
                    >
                        {productRange}
                    </p>
                )}
            </a>

            <div className="flex-grow">
                {productName && (
                    <p
                        {...productNameProps}
                        className={`font-bold text-[18px] leading-[22px] mt-[10px] ${productNameProps?.className}`}
                        data-testid={`product-card__description`}
                    >
                        {productName}
                    </p>
                )}

                {sizesAvailable > 0 && (
                    <p
                        {...productNameProps}
                        className={`font-bold text-[16px] leading-[20px] mt-[10px] text-brand-dark-grey ${productNameProps?.className}`}
                        data-testid={`product-card__sizes`}
                    >
                        {sizesAvailable}{" "}
                        {sizesAvailable === 1 ? "Size" : "Sizes"}
                    </p>
                )}
            </div>
        </div>
    );
};
ProductCard.defaultProps = {
    incTax: true,
    loggedIn: false,
    link: function () {
        console.log("clicking product link");
    },
    onAddToBasket: function (_e, product) {
        console.log("clicking add to basket", product);
    },
    onAddToWishlist: function (_e, product) {
        console.log("clicking add to wishlist", product);
    },
};
