import React from "react";

import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";
import File from "@peracto/peracto-editor-ui/dist/File";
import Select from "@peracto/peracto-editor-ui/dist/Select";
import Checkbox from "@peracto/peracto-editor-ui/dist/Checkbox";

const ImageForm = ({
    onChange,
    state: {
        url = "",
        mobileUrl = "",
        largeImageUrl,
        imageZoom = "none",
        alt = "",
        title = "",
        alignment = "fill",
        link = "",
        openInNewTab = false,
        loadingMethod = "normal",
        borderRadius = true,
        imageStyle = "",
    },
}) => {
    const alignmentOptions = [
        { label: "Full Width", value: "fill" },
        { label: "Left", value: "flex-start" },
        { label: "Center", value: "center" },
        { label: "Right", value: "flex-end" },
    ];

    const imageStyleOptions = [
        { label: "None", value: "" },
        { label: "Round", value: "round" },
        { label: "Greyscale", value: "greyscale" },
        { label: "Invert", value: "invert" },
        { label: "Blur", value: "blur" },
    ];

    const loadingMethodOptions = [
        { label: "Normal", value: "normal" },
        { label: "Preload", value: "preload" },
        { label: "Lazyload", value: "lazyload" },
    ];

    const imageZoomOptions = [
        { label: "None", value: "none" },
        { label: "Lightbox", value: "lightbox" },
        { label: "Magnify", value: "magnify" },
    ];

    return (
        <>
            <File
                label="Image"
                value={url}
                onChange={(value) => onChange({ url: value })}
                isInline={false}
            />

            <File
                label="Mobile Image"
                value={mobileUrl}
                onChange={(value) => onChange({ mobileUrl: value })}
                isInline={false}
            />

            <TextInput
                label="Image title"
                value={title}
                onChange={(e) => onChange({ title: e.target.value })}
            />

            <TextInput
                label="Image alt text"
                value={alt}
                onChange={(e) => onChange({ alt: e.target.value })}
            />

            <Select
                label="Alignment"
                value={alignment}
                onChange={(e) => onChange({ alignment: e.value })}
                options={alignmentOptions}
            />

            <Select
                label="Image Zoom"
                value={imageZoom}
                onChange={(e) => onChange({ imageZoom: e.value })}
                options={imageZoomOptions}
            />

            <Checkbox
                label="Rounded Corners"
                checked={borderRadius}
                onChange={(e) => onChange({ borderRadius: e.target.checked })}
            />

            {!!imageZoom && imageZoom !== "none" && (
                <File
                    label="Zoom Image URL"
                    value={largeImageUrl}
                    onChange={(value) => onChange({ largeImageUrl: value })}
                    isInline={false}
                />
            )}

            {(!!imageZoom === false || imageZoom === "none") && (
                <>
                    <hr />

                    <TextInput
                        label="Image link"
                        value={link}
                        onChange={(e) => onChange({ link: e.target.value })}
                    />

                    <Checkbox
                        label="Open in new tab"
                        checked={openInNewTab}
                        onChange={(e) =>
                            onChange({ openInNewTab: e.target.checked })
                        }
                    />

                    <Select
                        label="Loading method"
                        value={loadingMethod}
                        onChange={(e) => onChange({ loadingMethod: e.value })}
                        options={loadingMethodOptions}
                        tooltip="Preload should be used sparingly to improve performance, limit use to large above the fold images."
                    />
                </>
            )}

            <hr />

            <Select
                label="Image style"
                value={imageStyle}
                onChange={(e) => onChange({ imageStyle: e.value })}
                options={imageStyleOptions}
            />
        </>
    );
};

export default ImageForm;
