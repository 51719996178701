import React from 'react'
import usePortal from 'react-cool-portal'

import { Modal } from './Modal'
import { ModalOverlay, ModalContent, ModalFooter, ModalBody } from './Modal/components'

import { StandardImage } from './StandardImage'

export const LightboxImage = props => {
    const { url, largeImageUrl, alt, title, isEditMode } = props

    const { Portal, isShow, show, hide } = usePortal({
        defaultShow: false,
    })

    return (
        <>
            <StandardImage
                {...props}
                onClick={() => {
                    if (!isEditMode) {
                        show()
                    }
                }}
            />

            <Portal>
                <Modal isOpen={isShow} onClose={hide}>
                    <ModalOverlay />
                    <ModalContent
                        onClick={() => {
                            hide()
                        }}
                    >
                        <ModalBody>
                            <img
                                src={largeImageUrl || url}
                                className="w-full"
                                alt={alt || title || url}
                            />
                        </ModalBody>

                        {title && <ModalFooter>{title}</ModalFooter>}
                    </ModalContent>
                </Modal>
            </Portal>
        </>
    )
}
