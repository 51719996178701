import createPlugin from "./createPlugin";

import ImageRenderer from "./Renderer/ImageRenderer";

const imagePlugin = (settings) =>
    createPlugin({
        Renderer: ImageRenderer,
        ...settings,
    });

const image = imagePlugin();
export default image;

export { imagePlugin, ImageRenderer };
