const { darken, lighten } = require("polished");

// Used so hover colors get automatically updated
const brandPrimaryColor = "#00A7B5";
const brandBlackColor = "#283433";
const brandDarkGreyColor = "#808A94";

const colors = {
    "brand-primary": brandPrimaryColor,
    "brand-success": "#2DB200",
    "brand-danger": "#E30613",
    "brand-warning": "#F49C10",
    "brand-info": "#0697E3",
    "brand-pale-danger": "#FED7D7",
    "brand-white": "#FFFFFF",
    "brand-light-grey": "#F7F7F7",
    "brand-grey": "#CCCCCC",
    "brand-dark-grey": brandDarkGreyColor,
    "brand-black": brandBlackColor,

    // Hover colors
    "brand-primary-lighten": lighten(0.05, brandPrimaryColor),
    "brand-primary-darken": darken(0.05, brandPrimaryColor),

    "brand-dark-grey-lighten": lighten(0.05, brandDarkGreyColor),
    "brand-dark-grey-darken": "#67707A",

    "brand-black-lighten": lighten(0.05, brandBlackColor),
    "brand-black-darken": "#121717",
};

const fonts = {
    heading: ["canada-type-gibson, sans-serif"],
    body: ["canada-type-gibson, sans-serif"],
};

module.exports = { colors, fonts };
