import React from "react";
import { Field } from "formik";

export type TextareaProps = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  /** This is used to show a label above the input. */
  label?: string;
  /** This is used when there is an error to display the error styles for the input. */
  hasError?: boolean;
  /** This is used when there is an error to display a message to the user. */
  errorMessage?: string;
  /** This is used to disable the hover styles when using the input on certain background colours e.g. grey hover on grey background. */
  disableHoverStyles?: boolean;
};

export const Textarea = (props: TextareaProps) => {
  const {
    hasError = false,
    errorMessage,
    className = "",
    label,
    required,
    ...restProps
  } = props;

  return (
    <>
      {label && props.type !== "hidden" && (
        <label
          htmlFor={restProps?.name || restProps?.id}
          className={`mb-[10px] text-sm block font-light`}
        >
          {label}{" "}
          {required ? (
            <span className="text-brand-danger">*</span>
          ) : (
            <span className="italic text-[12px] font-light text-brand-dark-grey">
              {" "}
              &#8212; optional
            </span>
          )}
        </label>
      )}

      <textarea
        className={`w-full text-[14px] px-[15px] pt-[13px] pb-[14px] rounded-[22px] border placeholder-opacity-50 placeholder-brand-black transition-color duration-200 disabled:cursor-not-allowed focus:border-brand-primary outline-none ${
          hasError ? "border-brand-danger" : "border-brand-grey"
        } ${errorMessage ? "mb-[5px]" : ""} ${className}`}
        {...restProps}
      />

      {errorMessage && (
        <p className={`text-xs mt-0 mb-[15px] text-brand-danger block`}>
          {errorMessage}
        </p>
      )}
    </>
  );
};

export const FormikTextarea = (props) => {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <Textarea
          aria-label={props.label}
          onChange={(e) => form.setFieldValue(field.name, e.target.value)}
          isDisabled={form.isSubmitting || props.disabled}
          value={field.value}
          {...props}
        />
      )}
    </Field>
  );
};
