import React, { useMemo } from 'react'
import type { HTMLAttributes } from 'react'

type PresetSizeTypes = 'base' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type PresetSizeProps = { [key in PresetSizeTypes]?: string }

export type ImageProps = {
    src: string
    alt?: string
    presets?: { [key in PresetSizeTypes]?: string }
    maxImageHeight?: { [key in PresetSizeTypes]?: string }
} & HTMLAttributes<HTMLImageElement>

const generatePresetUrl = (url: string, preset: string) => {
    if (!url?.includes('cloudfront')) return url
    const split = url.split('/images')
    split.splice(1, 0, `/images/presets/${preset}`)
    return split.join('')
}

const breakpoints = {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1210px',
}

export const Image: React.VFC<ImageProps> = (props) => {
    const { src, presets = {}, maxImageHeight, alt = '', title = '', ...restProps } = props

    return useMemo(
        () => (
            <div data-testid="image" {...restProps}>
                <picture className="h-full w-full">
                    {['xl', 'lg', 'md', 'sm'].map((breakpoint) =>
                        !!breakpoints[breakpoint] && !!presets[breakpoint] ? (
                            <source
                                key={`image_${breakpoint}`}
                                srcSet={generatePresetUrl(src, presets[breakpoint])}
                                media={`(min-width: ${breakpoints[breakpoint]})`}
                                data-testid={`image-source-${breakpoint}`}
                            />
                        ) : null,
                    )}

                    {!!presets['base'] && (
                        <source
                            srcSet={generatePresetUrl(src, presets['base'])}
                            media={`(min-width: 0px)`}
                            data-testid="image-source-base"
                        />
                    )}
                    <img className={`w-full h-full`} src={src} alt={alt} title={title} />
                </picture>
            </div>
        ),
        [alt, src, title, presets, restProps],
    )
}
