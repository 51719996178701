import React, { useState } from 'react'
import { GlassMagnifier } from 'react-image-magnifiers'

export const MagnifyImage = props => {
    const { url, alt, title, largeImageUrl } = props

    // Prevents the zoomed image from loading when it's not required.
    // This is to improve page speed performance.
    const [isInteracted, setIsInteracted] = useState(false)

    return (
        <div
            className="w-full"
            onMouseEnter={e => {
                if (!isInteracted) {
                    setIsInteracted(true)
                }
            }}
        >
            <GlassMagnifier
                imageSrc={url}
                imageAlt={alt || title}
                magnifierSize="33%"
                largeImageSrc={isInteracted ? largeImageUrl || url : null}
                style={{
                    width: '100%',
                }}
            />
        </div>
    )
}
