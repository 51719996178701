import React, { HTMLAttributes } from 'react'
import ReactSelect, { components } from 'react-select'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'

type SelectInputProps = {
    options: {
        value: string
        label: string
        selected?: boolean
        disabled?: boolean
    }[]
    name: string
    label?: string
    required?: boolean
    value?: { value: string; label: string }
    large?: boolean
    onChange: (item: { value: string; label: string }) => void
} & HTMLAttributes<HTMLDivElement>

export const SelectInput = (props: SelectInputProps) => {
    const { className, name, label, large, required, options, onChange, value } = props
    return (
        <>
            {label?.length > 0 && (
                <label htmlFor={name} className={`mb-[10px] text-sm block font-light`}>
                    {label}{' '}
                    {required ? (
                        <span className="text-brand-danger">*</span>
                    ) : (
                        <span className="italic text-[12px] font-light text-brand-dark-grey">
                            {' '}
                            &#8212; optional
                        </span>
                    )}
                </label>
            )}

            <div data-testid="react-select-component">
                <ReactSelect
                    onChange={onChange}
                    value={value}
                    options={options}
                    className={`react-select ${className}`}
                    styles={{
                        control: (provided, { isFocused, menuIsOpen }) => ({
                            ...provided,
                            padding: '0 5px',
                            height: large ? '50px' : '45px',
                            fontWeight: 'normal',
                            backgroundColor: '#fff',
                            cursor: 'pointer',
                            borderColor: '#cccccc',
                            borderWidth: '1px',
                            borderBottom: menuIsOpen ? 'none' : '1px solid #cccccc',
                            borderRadius: menuIsOpen ? '23px 23px 0 0' : '23px',
                            color: '#101820',
                            boxShadow: isFocused ? 'none' : 'none',
                            fontSize: '14px',
                            '&:hover': {
                                borderColor: '#cccccc',
                            },
                            transition: 'none',
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            height: large ? '50px' : '45px',
                            lineHeight: '30px',
                            borderColor: '#cccccc',
                        }),
                        indicatorsContainer: (provided) => ({
                            ...provided,
                            position: 'relative',
                            top: '-2px',
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            padding: '0',
                            color: '#101820',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            width: 'calc(100% - 2px)',
                            margin: '0',
                            left: '1px',
                            right: '1px',
                            padding: '0px',
                            color: '#101820',
                            backgroundColor: '#fff',
                            border: 'none',
                            borderRadius: '0px 0px 23px 23px',
                            boxShadow: '0 0 0 1px #cccccc, 0 4px 11px #cccccc',
                            overflow: 'hidden',
                            cursor: 'pointer',
                        }),
                    }}
                    placeholder={
                        <p className="text-[16px] leading-[36px]">
                            {!name.startsWith('/form-fields/')
                                ? `Select a ${name}`
                                : 'Please select...'}
                        </p>
                    }
                    components={{
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                        Option,
                    }}
                    data-testid="select-input"
                />
            </div>
        </>
    )
}

const Option = ({
    innerProps,
    innerRef,
    children,
    isDisabled,
    isFocused,
    isSelected,
    ...restProps
}) => (
    <div
        ref={innerRef}
        style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 15px',
            fontSize: '14px',
            color: '#101820',
            opacity: isDisabled ? 0.7 : 1,
            fontWeight: 200,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            backgroundColor: isFocused ? '#F7F7F7' : '#FFFFFF',
            '&:active': {
                backgroundColor: '#F7F7F7',
            },
        }}
        {...innerProps}
    >
        <span>{children}</span>

        {isSelected && <FontAwesomeIcon icon={faCheck} />}
    </div>
)

const CaretDownIcon = () => {
    return (
        <p>
            <FontAwesomeIcon
                icon={faChevronDown as any}
                className="h-[14px] w-[14px] text-brand-black"
            />
        </p>
    )
}

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    )
}
