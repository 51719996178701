import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

import { fonts, colors } from "./tailwind";

// [container, breakpoint]
const sizes = {
  sm: ["520px", "576px"],
  md: ["720px", "768px"],
  lg: ["960px", "992px"],
  xl: ["1170px", "1200px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
  bp.push(size[1]);
  return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
  ...theme,
  breakpoints,
  colors: {
    ...theme.colors,
    brand: {
      blue: "#140F64",
      red: "#E11414",
      redLight: "#db5858",
      paleRed: "#FDF1F2",
    },
  },
  fonts: {
    ...theme.fonts,
    heading: "Mulish, sans-serif",
    body: "Mulish, sans-serif",
  },
};

export const AdminTheme = {
  breakpoints: {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 992,
    desktop: 1024,
    desktopL: 1700,
  },
};

// Global styles designs: link to the client's style guide if there is one.

export const GlobalStyles = css`
  /* HEADING AND TEXT */

  h1 {
    color: ${colors["brand-primary"]};
    font-weight: 200;
    font-size: 36px;
    line-height: 40px;

    &.heading1-huge {
      font-size: 60px;
      line-height: 70px;
    }
  }

  h2 {
    color: ${colors["brand-primary"]};
    font-weight: 200;
    font-size: 32px;
    line-height: 36px;
  }

  h3 {
    color: ${colors["brand-primary"]};
    font-weight: 200;
    font-size: 28px;
    line-height: 32px;
  }

  h4 {
    color: ${colors["brand-primary"]};
    font-weight: 200;
    font-size: 24px;
    line-height: 28px;
  }

  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  h6 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${fonts.heading};
  }

  a[type="button"] {
    &:hover {
      text-decoration: none;
    }
  }

  p {
    font-family: ${fonts.body};
    font-size: 16px;
    font-weight: 200;
    line-height: 22px;
  }

  .text-tiny {
    font-family: ${fonts.body};
    font-size: 14px;
    line-height: 20px;
  }

  .text-small {
    font-family: ${fonts.body};
    font-size: 12px;
    line-height: 18px;
  }

  blockquote {
    font-size: 18px;
    line-height: 26px;
    font-style: normal !important;
    padding-left: 30px !important;
    border-left: 4px solid ${colors["brand-primary"]} !important;
  }

  .content__text,
  .ck-content {
    padding-left: 0;
    padding-right: 0;

    .image {
      img {
        border-radius: 25px;
      }

      &:not(.image-style-side) {
        width: 100%;

        img {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }

    blockquote {
      margin: 30px 0;
      p {
        font-weight: 600;
        margin: 0;
      }
    }

    img {
      border-radius: 25px;
      width: 100%;
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 30px;
    }
  }

  /* LIST */

  ul:not([class^="DayPicker"]):not(.indicators) {
    list-style: none;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    position: relative;

    li {
      line-height: 24px;
      padding-left: 20px;
      position: relative;
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:before {
        display: block;
        position: absolute;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 9999px;
        background-color: ${colors["brand-primary"]};
        content: "";
        top: 8px;
        margin-right: 10px;
      }
    }
  }

  ol:not(.breadcrumb):not(.jump-to) {
    list-style: none;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    position: relative;
    padding-left: 0;
    counter-reset: list-number;
    font-size: 16px;
    line-height: 24px;

    li {
      margin-bottom: 15px;
      position: relative;
      display: flex;
      counter-increment: list-number;
      &:before {
        display: block;
        content: "";
        margin-right: 5px;
        content: counter(list-number) ".";
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  textarea {
    min-height: 45px;
    border-radius: 22px !important;
  }

  /* TABLE */
  .table {
    td {
      vertical-align: top;
      padding: 15px;
    }
  }

  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }

  /* CAROUSEL */
  .carousel-root {
    width: 100%;
  }

  .carousel {
    ul {
      margin-bottom: 0;
      list-style: none;

      li {
        padding-left: 0;

        &:before {
          content: none;
        }
      }
    }

    .slide {
      text-align: left;
      background: transparent;

      ul {
        margin-bottom: 0;

        li {
          padding-left: 0;

          &:before {
            content: none;
          }
        }
      }
    }

    @media all and (min-width: 992px) {
      .slider {
        z-index: 1;
      }
    }

    .control-dots {
      display: flex;
      justify-content: center;
      bottom: -40px;
    }

    &.carousel-slider {
      overflow: visible;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  /* RANGE SLIDER */
  .DefaultProgressBar_progressBar {
    background-color: ${colors["brand-primary"]} !important;
    height: 10px !important;
    top: -1px !important;
  }

  .DefaultHandle_handle {
    width: 20px !important;
    height: 20px !important;
    border-radius: 9999px !important;
    border-width: 2px !important;

    &::before {
      background-color: transparent !important;
    }

    &::after {
      background-color: transparent !important;
    }
  }

  .DefaultBackground {
    border-radius: 9999px !important;
    background-color: transparent !important;
    height: 10px !important;
  }

  /* BRANCH FINDER */
  .google-places-autocomplete {
    width: 100%;
    z-index: 1000;
  }

  /* DATE PICKER */
  .peracto-date-picker {
    ul {
      list-style-type: none;
    }

    .CalendarMonthGrid,
    .CalendarMonth,
    .DayPicker {
      background-color: ${colors["brand-white"]} !important;
    }

    .CalendarMonth_caption {
      color: ${colors["brand-black"]} !important;
      font-family: Roboto, sans-serif;
    }

    .CalendarDay {
      border: none;
    }

    .DayPicker__withBorder {
      margin: auto;
      box-shadow: none;
    }

    .CalendarDay {
      &__default {
        background-color: ${colors["brand-white"]} !important;
      }
      &__selected {
        background-color: ${colors["brand-primary"]} !important;
        border-color: ${colors["brand-primary"]} !important;

        &_span {
          background-color: ${colors["brand-light-grey"]} !important;
          border-color: ${colors["brand-white"]} !important;
          color: ${colors["brand-black"]} !important;
        }
      }
    }

    .DateRangePickerInput {
      width: 226px;
    }

    .DateInput,
    .DateInput_input {
      width: 60px;
    }

    .DateInput_input {
      font-size: 14px;
      font-weight: bold;
      height: 45px;
      padding-left: 0;
      padding-right: 0;

      svg {
        fill: #000;
      }
    }

    .DateRangePickerInput_arrow_svg {
      margin-right: 10px;
    }

    .DateRangePickerInput_calendarIcon_svg {
      fill: ${colors["brand-black"]} !important;
    }

    .CalendarMonth_caption {
      font-size: 12px;
      height: 86px;
    }

    .CalendarDay {
      font-size: 12px;
    }

    .DateInput_fangStroke {
      fill: ${colors["brand-light-grey"]} !important;
    }

    .DateInput_input__focused {
      border-color: ${colors["brand-black"]} !important;
    }
  }

  /* CHECKOUT DATE PICKER */

  .react-datepicker {
    padding: 10px 20px;
    font-family: ${fonts.body} !important;
    border-color: ${colors["brand-grey"]} !important;
    width: 100%;
    border-radius: 15px !important;

    .react-datepicker__header {
      background-color: white !important;
      border-bottom-color: ${colors["brand-grey"]};
    }

    .react-datepicker__navigation {
      top: 10px;
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      top: 15px;
      border: 0;
      border-color: none !important;
      height: 20px;
      width: 20px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }

    .react-datepicker__navigation--previous {
      left: 30px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLWNpcmNsZS1sZWZ0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUxMiA1MTIiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGV2cm9uLWNpcmNsZS1sZWZ0IGZhLXctMTYgZmEtM3giPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTI1NiA1MDRDMTE5IDUwNCA4IDM5MyA4IDI1NlMxMTkgOCAyNTYgOHMyNDggMTExIDI0OCAyNDgtMTExIDI0OC0yNDggMjQ4ek0xNDIuMSAyNzNsMTM1LjUgMTM1LjVjOS40IDkuNCAyNC42IDkuNCAzMy45IDBsMTctMTdjOS40LTkuNCA5LjQtMjQuNiAwLTMzLjlMMjI2LjkgMjU2bDEwMS42LTEwMS42YzkuNC05LjQgOS40LTI0LjYgMC0zMy45bC0xNy0xN2MtOS40LTkuNC0yNC42LTkuNC0zMy45IDBMMTQyLjEgMjM5Yy05LjQgOS40LTkuNCAyNC42IDAgMzR6IiBjbGFzcz0iIj48L3BhdGg+PC9zdmc+");
    }

    .react-datepicker__navigation--next {
      right: 30px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLWNpcmNsZS1yaWdodCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hldnJvbi1jaXJjbGUtcmlnaHQgZmEtdy0xNiBmYS0zeCI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMjU2IDhjMTM3IDAgMjQ4IDExMSAyNDggMjQ4UzM5MyA1MDQgMjU2IDUwNCA4IDM5MyA4IDI1NiAxMTkgOCAyNTYgOHptMTEzLjkgMjMxTDIzNC40IDEwMy41Yy05LjQtOS40LTI0LjYtOS40LTMzLjkgMGwtMTcgMTdjLTkuNCA5LjQtOS40IDI0LjYgMCAzMy45TDI4NS4xIDI1NiAxODMuNSAzNTcuNmMtOS40IDkuNC05LjQgMjQuNiAwIDMzLjlsMTcgMTdjOS40IDkuNCAyNC42IDkuNCAzMy45IDBMMzY5LjkgMjczYzkuNC05LjQgOS40LTI0LjYgMC0zNHoiIGNsYXNzPSIiPjwvcGF0aD48L3N2Zz4=");
    }

    .react-datepicker__current-month {
      font-size: 0.8rem;
      margin-bottom: 10px;
    }

    .react-datepicker__month-container,
    .react-datepicker__month {
      width: 100%;
      margin: 0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: calc(90% / 7);
      border-radius: 0;
      border: 1px solid transparent;
    }

    .react-datepicker__day--selected {
      background-color: ${colors["brand-primary"]} !important;
      color: ${colors["brand-white"]} !important;
      font-weight: normal;
      outline: none !important;
      border-color: ${colors["brand-grey"]};
      border-radius: 3px;
    }
  }

  /* ADMIN CONTENT BLOCK */
  .ory-row {
    font-family: ${fonts.body};
    color: ${colors["brand-black"]};
    font-weight: 200;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    text-rendering: optimizelegibility;

    a:not([type^="button"]) {
      text-decoration-line: underline;
      color: ${colors["brand-primary"]};

      &:hover {
        color: ${colors["brand-primary-darken"]};
      }
    }

    form {
      textarea,
      input {
        margin-bottom: 15px;
      }
    }

    .content__text,
    .ck-content {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 10px;
      }
    }

    .content__products {
      padding-left: 15px;
      padding-right: 15px;
    }

    .content__menu-items {
      .content-menu-items__parent-title {
        margin-bottom: 5px;
      }
      .content-menu-items__link-text {
        color: inherit !important;
      }
    }

    .content__menu-items {
      gap: 15px 0px;

      .content-menu-items__image {
        border-radius: 15px;
      }

      .content-menu-items__link {
        text-decoration: none;
        font-weight: 200;
      }

      .content-menu-items__link-text {
        text-align: left;
        font-size: 18px;
        line-height: 20px;
        margin-top: 15px;
        margin-bottom: 0;
        font-weight: 200 !important;

        @media all and (min-width: 992px) {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }

    .content__accordion {
      > div > div {
        margin-top: 0;
      }
    }

    .content__carousel {
      .react-slideshow-container {
        + ul {
          &.indicators {
            bottom: 60px;

            li {
              height: 15px;
              width: 15px;
              padding: 0;
              margin-right: 15px;

              &:last-child {
                margin-right: 0;
              }

              button {
                &.each-slideshow-indicator {
                  opacity: 1;

                  &:before {
                    border: 1px solid;
                    border-color: #ffffff;
                    background-color: transparent;
                    height: 15px;
                    width: 15px;
                  }

                  &.active {
                    &:before {
                      background-color: #ffffff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    figure.table {
      width: 100%;

      table {
        margin: 15px 0;
        min-width: 100%;
        font-size: 14px;
        line-height: 20px;

        tbody {
          > tr {
            &:first-of-type {
              font-weight: 400;
              font-size: 16px;
              background-color: ${colors["brand-primary"]} !important;
              color: ${colors["brand-white"]};
              border-top: none;

              td {
                border-top: none;
              }
            }

            &:last-of-type {
              border-radius: 0 0 13px 13px;
              border-bottom: none;

              td {
                border-bottom: none;
              }
            }

            &:nth-child(odd) {
              background-color: ${colors["brand-light-grey"]};
            }

            &:nth-child(even) {
              background-color: transparent;
            }
          }

          tr {
            td {
              padding: 15px;
            }
          }
        }
      }
    }
  }

  /* INPUTS */

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* ALGOLIA */
  .ais-ClearRefinements {
    .ais-ClearRefinements-button {
      text-decoration: underline;
    }
  }

  /* CATEGORY */

  .category-description {
    h2 {
      margin-top: 30px;
      margin-bottom: 10px;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 22px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      color: ${colors["brand-primary"]};
      text-decoration: underline;
    }
  }

  /* PDP */

  .pdp__product-text {
    font-size: 14px;
    line-height: 20px;

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 30px;
      margin-bottom: 10px;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    ul {
      li {
        margin-bottom: 10px;
        line-height: 24px;
        font-size: 14px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:before {
          top: 6px;
        }
      }
    }
  }

  /* VISITED LINK STYLES */

  .ory-row .content__text,
  .pdp__product-text,
  .category-description {
    a:not([type^="button"]) {
      &:visited {
        color: #0514c2;
      }
    }
  }
`;
