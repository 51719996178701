import React from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";

import { primary, secondary, tertiary } from "../../theme/buttonVariants";

export const Button = ({
    variantColor = "primary",
    variantStyle = "solid",
    loading = false,
    className = "",
    children,
    ...restProps
}) => {
    let variantClassName = "";

    if (variantColor === "primary") {
        variantClassName =
            variantStyle === "solid"
                ? primary?.solidClasses
                : primary?.outlineClasses;
    }

    if (variantColor === "secondary") {
        variantClassName =
            variantStyle === "solid"
                ? secondary?.solidClasses
                : secondary?.outlineClasses;
    }

    if (variantColor === "tertiary") {
        variantClassName =
            variantStyle === "solid"
                ? tertiary?.solidClasses
                : tertiary?.outlineClasses;
    }

    return (
        <button
            className={`${twMerge(`${variantClassName} ${className}`)}`}
            {...restProps}
        >
            {loading ? (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            ) : (
                children
            )}
        </button>
    );
};

export default Button;
