import React from "react";
import { ReactNode, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "@emotion/styled";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    label: ReactNode;
    dark?: boolean;
};

const CheckboxStyles = styled.label`
    &:hover {
        .checkbox {
            border-color: #00a7b5;
        }

        &.checkbox-dark {
            .checkbox {
                border-color: #00a7b5;
            }
        }
    }
`;

export const Checkbox = forwardRef<HTMLInputElement, InputProps>(
    (props, ref) => {
        const {
            className,
            checked,
            label,
            dark,
            onChange,
            disabled,
            ...restProps
        } = props;

        return (
            <CheckboxStyles
                className={`flex gap-x-[15px] items-center text-[16px] leading-[24px] ${
                    disabled ? "cursor-not-allowed" : "cursor-pointer"
                } ${dark ? "checkbox-dark" : ""} `}
            >
                <input
                    ref={ref}
                    type="checkbox"
                    checked={checked}
                    className={`hidden`}
                    {...(onChange && { onChange })}
                    disabled={disabled}
                />
                <span
                    className={twMerge(
                        `checkbox relative block w-4 h-4 border hover:border-brand-primary cursor-pointer rounded ${
                            checked
                                ? dark
                                    ? "bg-brand-black border-brand-black"
                                    : "bg-brand-primary border-brand-primary"
                                : "bg-transparent border-brand-grey"
                        } ${className} `
                    )}
                    {...restProps}
                >
                    {checked && (
                        <span
                            className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center`}
                        >
                            <FontAwesomeIcon
                                icon={faCheck}
                                className={`text-brand-white text-xs`}
                            />
                        </span>
                    )}
                </span>
                {label}
            </CheckboxStyles>
        );
    }
);
