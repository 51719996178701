import React from 'react'
import { twMerge } from 'tailwind-merge'

const wrapEvent = (theirHandler, ourHandler) => event => {
    if (theirHandler) {
        theirHandler(event)
    }

    if (!event.defaultPrevented) {
        return ourHandler(event)
    }
}

export const ModalWrapper = props => {
    const {
        isOpen,
        onClose,
        isCentered = true,
        closeOnOverlayClick = true,
        closeOnEsc = true,
        scrollBehavior = 'inside',
        noStyles,
        size = 'md',
        className,
        children,
        onClick,
        ...restProps
    } = props

    let wrapperClassName = ''
    let contentClassName = ''

    if (isCentered) {
        wrapperClassName = 'flex items-center justify-center'
    } else {
        contentClassName = 'top-[50%] mx-auto'
    }

    if (scrollBehavior === 'inside') {
        wrapperClassName = `${wrapperClassName} max-h-[calc(100vh - 7.5rem)] overflow-hidden top-[20vh]`
        contentClassName = `${contentClassName} h-full top-0`
    }

    if (scrollBehavior === 'outside') {
        wrapperClassName = `${wrapperClassName} overflow-y-auto overflow-x-hidden`
        contentClassName = `${contentClassName} my-[3.75rem] top-0`
    }

    if (noStyles) {
        wrapperClassName = ''
        contentClassName = ''
    }

    const widths = {
        sm: '200px',
        md: '600px',
        lg: '800px',
        xl: '90vw',
    }

    return (
        isOpen && (
            <div
                className={twMerge(
                    `fixed left-0 top-0 w-screen h-screen z-[999999]`,
                    wrapperClassName
                )}
                onClick={event => {
                    event.stopPropagation()
                    if (closeOnOverlayClick) {
                        onClose(event, 'clickedOverlay')
                    }
                }}
                onKeyDown={event => {
                    if (event.key === 'Escape') {
                        event.stopPropagation()
                        if (closeOnEsc) {
                            onClose(event, 'pressedEscape')
                        }
                    }
                }}
            >
                <section
                    className={twMerge(
                        'relative flex flex-col z-modal outline-0 w-full',
                        contentClassName
                    )}
                    role="dialog"
                    aria-modal="true"
                    tabIndex={-1}
                    style={{
                        maxWidth: widths[size],
                    }}
                    onClick={wrapEvent(onClick, event => event.stopPropagation())}
                    {...restProps}
                >
                    {children}
                </section>
            </div>
        )
    )
}

export const ModalOverlay = props => {
    return (
        <div
            className={
                'fixed z-[1000] bottom-0 top-0 left-0 right-0 overflow-y-auto bg-[rgba(16,22,26,0.7)] content__image--modal-overlay'
            }
            {...props}
        />
    )
}

export const ModalContent = props => {
    return (
        <div
            className={
                'w-full relative flex flex-col bg-brand-white max-w-[80vw] p-[1rem] cursor-zoom-out fixed z-[2000] content__image--modal-content'
            }
            {...props}
        />
    )
}

export const ModalHeader = props => {
    return (
        <header
            className={'px-6 py-4 relative text-xl font-[600] content__image--modal-header'}
            {...props}
        />
    )
}

export const ModalFooter = props => {
    return (
        <footer
            className={
                'flex px-6 pt-[1rem] pb-0 justify-center flex-col sm:flex-row border-t border-t-brand-grey text-center font-body pt-[1rem] pb-0 content__image--modal-footer'
            }
            {...props}
        />
    )
}

export const ModalBody = props => {
    return <div className={'p-0 flex-[1] content__image--modal-body'} {...props} />
}
