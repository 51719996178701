import React from "react";

export const SampleProductsTable = ({ data }) => {
    const sampleProducts = JSON.parse(data);

    return (
        <table className="table table-sm w-full mb-0">
            <tbody>
                {sampleProducts.map((product, idx) => {
                    return (
                        <React.Fragment key={`sample-product-${idx}`}>
                            <tr>
                                <td className={idx === 0 ? "border-top-0" : ""}>
                                    <p className="mb-0">
                                        <strong>
                                            {product.productName}{" "}
                                            {product?.decorCode
                                                ? `(${product.decorCode})`
                                                : ""}
                                        </strong>
                                    </p>
                                </td>
                                <td
                                    className={`text-right ${
                                        idx === 0 ? "border-top-0" : ""
                                    }`}
                                >
                                    <p className="mb-0">x 1</p>
                                </td>
                            </tr>
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>
    );
};
