import React from "react";

import { defaultSettings } from "./defaults/settings";
import Component from "./Component";
import Form from "./Form/ImageForm";

const createPlugin = (settings) => {
    const mergedSettings = { ...defaultSettings, ...settings };

    return {
        Component: (props) => <Component {...props} {...mergedSettings} />,
        FormComponent: (props) => <Form {...props} />,

        name: "peracto/content/image",
        version: "1.0.0",
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,

        createInitialState: () => ({
            text: mergedSettings.defaultText,
            loadingMethod: "normal",
        }),
    };
};

export default createPlugin;
