import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-regular-svg-icons/faImage";

import { LightboxImage, MagnifyImage, StandardImage } from "./imageTypes";

const ImageStyles = styled.div`
    display: flex;
    justify-content: ${(props) => props.alignment};
    align-items: center;
    overflow: auto;
    border: 0px;
    min-height: 40px;
    margin-bottom: -1px;

    a {
        display: flex;
        width: ${(props) => (props.alignment === "fill" ? "100%" : "auto")};
    }

    img {
        max-width: none;
        width: ${(props) => (props.alignment === "fill" ? "100%" : "auto")};
        align-self: center;
        transition: border-radius 0.1s ease-in-out;
        border-radius: ${(props) =>
            props.imageStyle === "round"
                ? "50%"
                : props.borderRadius
                ? "25px"
                : 0};
        filter: ${(props) => {
            switch (props.imageStyle) {
                case "greyscale":
                    return `grayscale(1)`;
                case "invert":
                    return `invert(1)`;
                case "blur":
                    return `blur(2px)`;
                default:
                    return `none`;
            }
        }};
    }
`;

let Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faImage} size="6x" />
    </div>
);

const ImageContent = (props) => {
    const {
        url = "",
        mobileUrl = "",
        alt = "",
        title = "",
        link = "",
        openInNewTab = false,
        loadingMethod = "normal",
        imageZoom = "none",
        isEditMode,
        alignment = "fill",
    } = props;
    return (
        <>
            {link.length > 0 && !["magnify", "lightbox"].includes(imageZoom) ? (
                <>
                    <a
                        onClick={(e) => isEditMode && e.preventDefault()}
                        rel={openInNewTab ? "nofollow noreferrer" : ""}
                        href={link}
                        target={openInNewTab ? "_blank" : "_self"}
                    >
                        <StandardImage
                            title={title.length > 0 ? title : alt}
                            alt={alt}
                            url={url}
                            mobileUrl={mobileUrl}
                            loadingMethod={loadingMethod}
                            isFullWidth={alignment === "fill"}
                        />
                    </a>
                </>
            ) : (
                <>
                    {(!imageZoom || imageZoom === "none") && (
                        <StandardImage
                            title={title.length > 0 ? title : alt}
                            alt={alt}
                            url={url}
                            mobileUrl={mobileUrl}
                            loadingMethod={loadingMethod}
                            isFullWidth={alignment === "fill"}
                        />
                    )}

                    {imageZoom === "magnify" && (
                        <MagnifyImage
                            isFullWidth={alignment === "fill"}
                            {...props}
                        />
                    )}
                    {imageZoom === "lightbox" && (
                        <LightboxImage
                            isFullWidth={alignment === "fill"}
                            {...props}
                        />
                    )}
                </>
            )}
        </>
    );
};

const ImageRenderer = ({ state, ...props }) => {
    const { alignment = "fill", imageStyle, url, borderRadius = true } = state;
    const inStorefront = !window?.location?.pathname.includes("/content/edit/");

    return (
        <ImageStyles
            alignment={alignment}
            imageStyle={imageStyle}
            borderRadius={borderRadius}
            className="content__image"
        >
            {url && url.length > 0 ? (
                <ImageContent {...state} {...props} />
            ) : inStorefront ? (
                <></>
            ) : (
                <Icon />
            )}
        </ImageStyles>
    );
};

export default ImageRenderer;
