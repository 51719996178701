import React, { HTMLAttributes } from 'react'

import { Field } from 'formik'
import { SelectInput } from './SelectInput'

type SelectProps = {
    options: {
        value: string
        label: string
        selected?: boolean
        disabled?: boolean
    }[]
    name: string
    label?: string
    required?: boolean
} & HTMLAttributes<HTMLDivElement>

export const Select = (props: SelectProps) => {
    const { name, options, label, required, className } = props

    return (
        <Field name={name}>
            {({ field, form }) => {
                const value =
                    field?.value?.length > 0 &&
                    options?.find((option) => option.value === field.value)

                return (
                    <>
                        {label?.length > 0 && (
                            <label htmlFor={name} className={`mb-[10px] text-sm block font-light`}>
                                {label}{' '}
                                {required ? (
                                    <span className="text-brand-danger">*</span>
                                ) : (
                                    <span className="italic text-[12px] font-light text-brand-dark-grey">
                                        {' '}
                                        &#8212; optional
                                    </span>
                                )}
                            </label>
                        )}

                        <div className="flex flex-col mb-[15px] justify-center">
                            <SelectInput
                                onChange={(item) => {
                                    form.setFieldValue(field.name, item.value)
                                }}
                                value={value}
                                options={options}
                                className={className}
                                name={field.name}
                                data-testid="select-input"
                            />
                        </div>
                    </>
                )
            }}
        </Field>
    )
}

export const FormikSelect = (props) => {
    return (
        <Field name={props.name}>
            {({ field, form }) => (
                <Select
                    aria-label={props.label}
                    onChange={(e) => form.setFieldValue(field.name, e)}
                    isDisabled={form.isSubmitting || props.disabled}
                    value={field.value}
                    placeholder={props.label}
                    {...props}
                />
            )}
        </Field>
    )
}
